<template>
  <div>
    <SimpleTable :tableProps="tableProps" ref="table" :queryFun="queryFun">
      <template slot="left-r">
        <el-button
          class="add-btn"
          icon="el-icon-circle-plus-outline"
          @click="toDetail(true)"
          >添加</el-button
        >
      </template>
      <template slot="right-r" slot-scope="{ data }"
        ><el-button
          icon="el-icon-upload2"
          class="export-btn"
          @click="exportData(data.params)"
          >导出</el-button
        ></template
      >
      <div slot="action" slot-scope="{ data }">
        <el-button type="text" @click="toDetail(false, data.row)"
          >查看详情</el-button
        >
        <el-button type="text" @click="toDetail(true, data.row)"
          >编辑</el-button
        >
        <el-button @click="deleteClaimItem(data.row)" type="text"
          >删除</el-button
        >
      </div>
    </SimpleTable>
  </div>
</template>

<script>
import SimpleTable from "./components/SimpleTable.vue";
import {
  getClaimList,
  exportInsuranceClaim,
  deleteInsuranceClaim,
} from "@/api/safeDuty.js";
export default {
  components: { SimpleTable },
  data() {
    return {
      tableProps: {
        fuzzyQueryKey: "fuzzySearch",
        currentPageKey: "current",
        currentSizeKey: "size",
        totalKey: "total",
        height: 500,
        searchConfigs: [
          {
            label: "案件编号",
            type: "input",
            modelKey: "caseNo",
          },
          {
            label: "报案号",
            type: "input",
            modelKey: "realCaseNo",
          },
          {
            label: "所属项目",
            type: "input",
            modelKey: "projectName",
          },
          {
            label: "被保险人",
            type: "input",
            modelKey: "insuredName",
          },
          {
            label: "出险时间",
            type: "daterange",
            daterangeKeys: ["dangerDateBegin", "dangerDateEnd"],
            //daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "dangerDate",
          },
          {
            label: "案件跟进人",
            type: "input",
            modelKey: "caseOfficer",
            option: [],
          },
          // {
          //   label: "报案时间",
          //   type: "daterange",
          //   daterangeKeys: ["dangerDateBegin", "dangerDateEnd"],
          //   daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
          //   modelKey: "dangerDate",
          // },
          {
            label: "录入时间",
            type: "daterange",
            daterangeKeys: ["createTimeBegin", "createTimeEnd"],
            //daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "createTime",
          },
        ],
        columnProps: [
          {
            prop: "1",
            label: "序号",
            width: "55",
            type: "index",
            isShow: true,
          },
          {
            prop: "caseNo",
            label: "案件编号",
            width: "",
            type: "",
            isShow: true,
          },

          {
            prop: "realCaseNo",
            label: "报案号",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "policyNo",
            label: "保单号",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "insuredName",
            label: "被保险人",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "projectName",
            label: "所属项目",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "caseOfficer",
            label: "案件跟进人",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "dangerDate",
            label: "出险时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "compensationTotalAmount",
            label: "赔付总金额",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "createTime",
            label: "录入时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "updateTime",
            label: "更新时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "action",
            label: "操作",
            width: "",
            type: "",
            isShow: true,
            slot: true,
            width: 180,
          },
        ],
      },
    };
  },
  methods: {
    toDetail(edit = false, item = {}) {
      this.$router.push({
        name: "safeDutyClaimDetail",
        params: {
          id: item?.id,
          isEdit: edit,
        },
      });
    },
    deleteClaimItem(item) {
      this.$confirm("是否删除", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteInsuranceClaim({ id: item.id }).then(() => {
          this.$refs.table.getData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    },
    //导出数据
    exportData(data) {
      exportInsuranceClaim(data);
    },
    async queryFun(queryParams) {
      let res = await getClaimList(queryParams);
      return {
        localData: res.data.records || [{}],
        total: res.data.total,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.add-btn {
  background: #00bc0d;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #00bc0d;
}
.dele-btn {
  background: #c94242;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #c94242;
}
.export-btn {
  background: #4278c9;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #4278c9;
}
</style>
